import {
  gddRangeToMsg,
  calculateGdd,
  baskervilleEmin,
} from "../../utils/helpers"
import { format } from "date-fns"

export default function PlumCurculioLogic(
  modelData,
  data,
  dateOfInterest,
  isModelInSeason,
  userBiofix1 = null,
  userPhenologicalStage = null,
  userPestStage = null
) {
  // console.count("ModelLogic------------------------")
  const { baseFormula, elements, baseFormulaDisplay, base } = modelData
  const { userInputs } = elements
  const {
    beforeBiofix1,
    afterBiofix1,
    phenologicalStagesBaseFormula,
  } = elements.managementGuide

  const dailyDataWithGdd = calculateGdd(
    data.dailyData,
    base,
    0,
    baskervilleEmin
  ).map((d) => {
    return { ...d, dateDisplay: format(d.date, "yyyy-MM-dd") }
  })

  // console.log(userBiofix1, userBiofix2)
  // User Biofix dates -------------------------------------------------------
  let userBiofix1Index = 0
  if (userBiofix1 !== null) {
    userBiofix1Index = dailyDataWithGdd.findIndex(
      (day) => day.dateDisplay === userBiofix1
    )
  }

  // Recalculating degree-days when biofix1 ----------------------------------
  let gdd1 = 0
  let gdd3Pheno = 0
  const mData = dailyDataWithGdd.map((day, i) => {
    const ddPheno = baskervilleEmin(day.mint, day.maxt, 43)
    gdd3Pheno += ddPheno === "N/A" ? 0 : ddPheno

    let p = {
      date: day.date,
      dateDisplay: day.dateDisplay,
      dayOfYear: day.dayOfYear,
      ms: day.ms,
      isForecast: day.isForecast,
      [`${baseFormula}dd`]: day.dd,
      [`${baseFormula}Gdd`]: day.gdd,
      displayBiofix1Input:
        day.gdd >= userInputs.firstInput.displayElement ? true : false,
      biofix1Gdd: "N/A",
      remainingDDFromBiofix1:
        userInputs.firstInput.biofix1 - day.gdd >= 0
          ? userInputs.firstInput.biofix1 - day.gdd
          : 0,
      biofix1Above150DD: false,
      [`${phenologicalStagesBaseFormula}dd`]: Math.round(ddPheno),
      [`${phenologicalStagesBaseFormula}Gdd`]: Math.round(gdd3Pheno),
    }

    // Recalculate if user has selected a biofix1 date
    if (userBiofix1 !== null) {
      if (i >= userBiofix1Index) {
        gdd1 += day.dd !== "N/A" ? day.dd : 0
        p.biofix1Gdd = Math.round(gdd1)
        p.biofix1Above150DD = gdd1 >= 150 && true
      }
    } else {
      if (day.gdd >= userInputs.firstInput.biofix1) {
        gdd1 += day.dd !== "N/A" ? day.dd : 0
        p.biofix1Gdd = Math.round(gdd1)
        p.biofix1Above150DD = gdd1 >= 150 && true
      }
    }
    return p
  })
  // console.log(mData)
  ////////////////////////////////////////////////////////////////////////////////////

  let selectedDate = mData.find(
    (day) => day.dayOfYear === dateOfInterest.dayOfYear
  )
  if (!selectedDate) {
    selectedDate = mData.find((d) => d.isForecast)
  }
  // console.log(selectedDate)

  // Biofix Dates ----------------------------------------------------------
  let biofix1 = null
  const b1 = mData.find((day) => day.biofix1Gdd !== "N/A")
  if (typeof b1 !== "undefined") biofix1 = b1

  if (biofix1) {
    if (selectedDate.dayOfYear < biofix1.dayOfYear) biofix1 = null
  }
  // console.log(biofix1, biofix2)
  ////////////////////////////////////////////////////////////////////////////////////

  // determine biofix message Below user inputs ------------------------------------
  let userInputMsg = ""
  if (isModelInSeason) {
    if (biofix1 === null) {
      userInputMsg = userInputs.firstInput.message.beforeBiofix1
    }
    if (biofix1) {
      userInputMsg = userInputs.firstInput.message.afterBiofix1
    }
  }
  ////////////////////////////////////////////////////////////////////////////////////

  // User input DD Accumulation ------------------------------------------------------
  let startDate = data.dailyData[0].date.toLocaleDateString()
  let gddDisplay = selectedDate[`${baseFormula}Gdd`]

  if (biofix1) {
    startDate = "petal fall"
    gddDisplay = selectedDate.biofix1Gdd
  }

  const accumulationDDMsg = {
    text: `Accumulated degree days ${baseFormulaDisplay} ${startDate} through ${data.dailyData[
      selectedDate.dayOfYear - 1
    ].date.toLocaleDateString()}:`,
    gdd: gddDisplay,
  }
  // console.log(accumulationDDMsg)
  // /////////////////////////////////////////////////////////////////////////////////////

  // Data to display in Results Table --------------------------------------------------
  let tableData = [...mData]
  if (mData.length >= 8) {
    tableData = mData.slice(
      selectedDate.dayOfYear - 3,
      selectedDate.dayOfYear + 5
    )
  }
  // console.log(tableData)
  // //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  let csvData = []
  if (isModelInSeason) {
    csvData = mData.map((day) => {
      if (biofix1) {
        return {
          Date: day.dateDisplay,
          [`Daily DD ${baseFormulaDisplay}`]: day[`${baseFormula}dd`],
          "From Jan 1": day[`${baseFormula}Gdd`],
          [`From ${format(biofix1.date, "LLL d")}`]: day.biofix1Gdd,
        }
      }
      return {
        Date: day.dateDisplay,
        [`Daily DD ${baseFormulaDisplay}`]: day[`${baseFormula}dd`],
        "From Jan 1": day[`${baseFormula}Gdd`],
      }
    })
  }
  // console.log(csvData)
  //////////////////////////////////////////////////////////////////////////////////////

  // Management Guide ------------------------------------------------------------------
  let managementGuide = {
    pestStage: "",
    pestStatus: "",
    pestManagement: "",
    dashboardDisplay: "",
  }

  const msg = gddRangeToMsg(beforeBiofix1, selectedDate[`${baseFormula}Gdd`])
  if (msg && msg !== "N/A") managementGuide = msg

  if (biofix1) {
    const msg = gddRangeToMsg(afterBiofix1, selectedDate.biofix1Gdd)
    if (msg && msg !== "N/A") managementGuide = msg
  }

  // console.log(managementGuide)
  //////////////////////////////////////////////////////////////////////////////////////

  // Determine Phenological Stage ----------------------------------------------------
  let phenologicalStage = null

  if (isModelInSeason) {
    if (!biofix1) {
      const res = gddRangeToMsg(
        elements.managementGuide.phenologicalStages,
        selectedDate[`${phenologicalStagesBaseFormula}Gdd`]
      )
      if (res) {
        if (userPhenologicalStage) {
          phenologicalStage = userPhenologicalStage
          managementGuide =
            elements.managementGuide.beforeBiofix1[
              userPhenologicalStage.beforeBiofix1
            ]
        } else {
          phenologicalStage = { ...res }
        }
      }
    }
  }
  // console.log(phenologicalStage, selectedDate["base43BEGdd"])
  //////////////////////////////////////////////////////////////////////////////////////

  // Determine Pest Stage -----------------------------------------------------------
  let pestStage = null

  if (biofix1) {
    const res = gddRangeToMsg(
      elements.managementGuide.afterBiofix1,
      selectedDate.biofix1Gdd
    )
    if (res) {
      if (userPestStage) {
        pestStage = { ...userPestStage }
        managementGuide =
          elements.managementGuide.afterBiofix1[userPestStage.key]
      } else {
        pestStage = { ...res, value: res.pestStage }
        managementGuide = { ...res }
      }
    }
  }

  // console.log(pestStage)
  //////////////////////////////////////////////////////////////////////////////////////

  if (!isModelInSeason) {
    managementGuide = { ...elements.managementGuide.outOfSeasonMsg }
  }

  // Dashboard Summary -----------------------------------------------------------------
  let dashboardSummary = {
    message: "",
    fromJan1: {
      text: `Accum. DD ${baseFormulaDisplay} since Jan 1:`,
      gdd: "N/A",
    },
    [biofix1 ? "biofix1" : null]: {
      text: `Accum. DD ${baseFormulaDisplay} from petal fall:`,
      gdd: "N/A",
    },
  }

  if (isModelInSeason === false) {
    dashboardSummary.message =
      elements.managementGuide.outOfSeasonMsg.dashboardDisplay
  } else {
    let currentDate = mData.find((d) => d.isForecast)
    if (currentDate) {
      dashboardSummary.message = managementGuide.dashboardDisplay
      dashboardSummary.fromJan1.gdd = currentDate[`${baseFormula}Gdd`]
      if (biofix1) {
        dashboardSummary.biofix1.gdd = currentDate.biofix1Gdd
      }
    }
  }
  // console.log(dashboardSummary)
  //////////////////////////////////////////////////////////////////////////////////////

  return {
    data: mData,
    biofix1,
    selectedDate,
    userInputMsg,
    accumulationDDMsg,
    tableData,
    csvData,
    managementGuide,
    phenologicalStage,
    pestStage,
    dashboardSummary,
  }
}
