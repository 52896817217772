import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Baskerville, G.L. and Emin, P. 1969. Rapid Estimation of Heat
        Accumulation from Maximum and Minimum Temperatures. Ecology. 50:
        514-517.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Reissig, W.H., Nyrop, J.P., and Straub, R. 1998. Oviposition model for
        timing insecticide sprays against plum curculio (Coleoptera:
        Curculionidae) in New York State. Environ. Entomol. 27: 1053-1061.
      </p>
    </>
  )
}
